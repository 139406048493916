import { api, i18n } from "@/config"
import { showToast } from "@/helpers/toasts"

export default ({ baseURI }) => ({
  UPDATE_TRANSLATION: async (context, { id, value, locale, key }) => {
    try {
      await api.put(`${baseURI}/${id}`, { translation: { value, locale, key } })
    } catch ({ response: { status } }) {
      showToast({
        text: i18n.t("activerecord.errors.models.translation.not_found")
      })
    }
  },

  APPLY_SEARCH({ commit, dispatch, state }, { search_value }) {
    commit("SET_PAGINATION_DATA", { ...state.pagination, current_page: 1 })
    commit("SET_FILTERS", { search_value })
    dispatch("FETCH_ITEMS")
  }
})
