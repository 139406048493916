import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import extractActions from "./actions"

const BASE_URI = "/maestro/translations"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch"],
  withPagination: true,
  withFilters: true,
  withSorting: {}
})

store.mergeState({})

const { UPDATE_TRANSLATION, APPLY_SEARCH } = extractActions({ baseURI: BASE_URI })
store.mergeActions({ UPDATE_TRANSLATION, APPLY_SEARCH }, withLoading)

export default store
