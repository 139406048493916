import { configPrepare } from "@/helpers/vue-good-table"

const defaults = {
  tdClass: "translations-table-td",
  thClass: "translations-table-th"
}

const i18nPrefix = "translations.table.columns"

const fields = [
  {
    label: `${i18nPrefix}.key`,
    field: "key"
  },
  {
    label: `${i18nPrefix}.english`,
    field: "en_value",
    thClass: "translations-table-th expandable-field",
    tdClass: "translations-table-td expandable-field"
  },
  {
    label: `${i18nPrefix}.japanese`,
    field: "ja_value",
    thClass: "translations-table-th expandable-field",
    tdClass: "translations-table-td expandable-field"
  }
]

export const columns = () =>
  configPrepare(fields, {
    i18nPrefix,
    defaults
  })
